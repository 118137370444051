import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ArrowTip, Css, Figma, Html, Illustrator, Java, Javascript, NodeJs, Photoshop, Php, ReactJs, VueJs, Wordpress } from '../features/icons';
import { colors } from '../features/presetStyles';

const Skills = () => {
    const [isSkillOpened, setIsSkillOpened] = useState(false);


    useEffect(() => {
        isSkillOpened && console.log("True");
    }, [isSkillOpened])

    const skillBox = useRef()
    return (
        <SkillsContainer
        // className={isSkillOpened ? "showBox" : undefined}
        // style={{ width: isSkillOpened ? "260px" : "33px" }}
        >
            <i
                onClick={() => setIsSkillOpened(!isSkillOpened)}
                className={isSkillOpened ? "animateArrow" : undefined}
            >
                <ArrowTip color={colors.corail} width="1em" height="1em" />
            </i>
            <div ref={skillBox}
                className={isSkillOpened ? "showBox" : undefined}
            >
                <div>
                    <h4>
                        Programmation
                    </h4>
                    <Technos>
                        <i>
                            <span>react.js</span>
                            <ReactJs color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                        <i>
                            <span>vue.js</span>
                            <VueJs color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                        <i>
                            <span>html</span>
                            <Html color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                        <i>
                            <span>css</span>
                            <Css color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                        <i>
                            <span>javascript</span>
                            <Javascript color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                        <i>
                            <span>java</span>
                            <Java color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                        <i>
                            <span>php</span>
                            <Php color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                        <i>
                            <span>wordpress</span>
                            <Wordpress color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                        <i>
                            <span>node.js</span>
                            <NodeJs color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                    </Technos>
                </div>

                <div>
                    <h4>
                        Graphisme
                    </h4>
                    <Technos>
                        <i>
                            <span>figma</span>
                            <Figma color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                        <i>
                            <span>photoshop</span>
                            <Photoshop color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                        <i>
                            <span>illustrator</span>
                            <Illustrator color={colors.greenBlack} width="1em" height="1em" />
                        </i>
                    </Technos>
                </div>
            </div>
            <div className={isSkillOpened ? undefined : "blurBox"}>
                <div>
                    <h3>Compétences</h3>
                </div>
            </div>
        </SkillsContainer>
    )
}

const SkillsContainer = styled.div`
     position: absolute;
        bottom:0 ;
        right: 0;
        width:0;
        height: 240px;
        transition: all 0.3s;
       
    
    h3, h4 {
            color: ${colors.green};
            font-size: 14px;
            font-family: "Poppins";
            font-weight: 400;
    }
    
    h4 {
        color: ${colors.greenBlack}
    }
    >i {
            position: absolute;
            z-index: 10;
            top: 10px;
            right:252px;
            display: grid;
            align-self: center; 
            cursor: pointer;
            filter: drop-shadow(0px -3px 1px rgb(0 0 0 / 20%));

            transform: translateX(226px) rotateZ(90deg);
            transition: all ease 1s;
        }

    >.animateArrow {
        transform: translateX(0) rotateZ(-90deg) !important;
        
    }
    > .showBox {
        transform: translateX(0) !important;
    } 
  
    > div:nth-child(2) {
        position:absolute;

        background-color: ${colors.beige};
        border-top-left-radius: 7px;
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 260px;
        height: 100%;
        padding: 10px;
        right:0;
        bottom: 0;
        transform: translateX(228px);
        transition: all 0.3s;
        
        background: linear-gradient(45deg,rgba(47,50,50,0.1),rgba(149,171,168,0.8));
        overflow: hidden;
        backdrop-filter: blur(4px);
        box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 25%);

        h4 {
            margin-bottom: 12px;
        }
        > div {
            margin-bottom: 20px;
            svg {
                //Technos
                font-size: 26px;
            }
        }
        i{
            position: relative;
            display: grid;
            align-self: center;
            span {
                visibility: hidden;
                position: absolute;
                background-color: ${colors.sweetGreen};
                color: ${colors.beige};
                bottom: -20px;
                left:-10px;
                padding: 0 8px;
                border-radius: 6px;
                z-index: 1;
                display:grid;
                justify-self: center;
                cursor: default;
            }
        }
        i:hover span {
            visibility: visible;
        }
        
    } 
   
    .blurBox {
        backdrop-filter: blur(4px);
        background-color: transparent !important;
        
        h3 {
            color: ${colors.greenBlack};
        }
    }
    > div:nth-child(3) {
        border-top-left-radius: 7px;
        background-color: azure;
        width: 33px;
        position: absolute;
        height: 100%;
        right: 0;
        transition: all ease 0.3s;
        /* backdrop-filter: unset; */
        h3 {
            font-weight: 500;
            transition: all 0.3s;
        }
        div {
            transform: rotate(90deg);
            transform-origin: left bottom;
            display: flex;
            align-items: center;
            margin: 0 auto;
            width: 21px;
        }

        i {
            height: 10px;
            width: 10px;
            background-color: teal;
            cursor: pointer;
        }
    }
   
`;

const Technos = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
`;
export default Skills