import React from 'react'


export const colors = {
    greenBlack: "#2F3232",
    greenBlackRGB: "47, 50, 50",
    sweetBeige: "#cac6c0",
    sweetBeigeRGB: "224, 221, 217",
    beige: "#EEE3D2",
    beigeRGB: "238, 227, 210",
    grey: "#7A7A7A",
    greyRGB: "122, 122, 122",
    corail: "#C9A390",
    corailRGB: "201, 163, 144",
    corailRGBWithoutcomam: "201 163 144",
    sweetGreen: "#95ABA8",
    sweetGreenRGB: "149, 171, 168",
    green: "#789A95",
    greenRGB: "120, 154, 149",
    lightGreen: "#B5DAD5",
    // lightGreenRgb: "181, 218, 213",
    lightGreenRgb: "181 218 213",
    arrowColor: "#C8D1BE",
    arrowColorRGB: "200, 209, 190"
}

export const sizes = {
    sliderBoxHeight: 110 + "px",
    sliderBoxWidth: 180 + "px",
    sliderFrstBoxHeight: 110 * 1.2 + "px",
    sliderFrstBoxWidth: 180 * 1.2 + "px",
    sliderGap: 16 + "px"
}


