import React from 'react'


export const ProjectDatas = [
  {
    "title": "vitanimal",
    "sub_title": "application mobile en PWA",
    "thumbnail": "/images/projects/vitanimal/oise.jpg",
    "technos": [
      "figma/xd",
      "react.js",
      "javascript",
      "html",
      "css",
      "django",
      "pwa"
    ],
    "gitLink": ""
  },
  {
    "title": "disney+",
    "sub_title": "Clonage du site de disney",
    "thumbnail": "/images/projects/disney/disney_thumbnail.png",
    "technos": ["react.js", "javascript", "html", "css", "redux"],
    "images": [
      {
        "images1": "image1",
        "images2": "image2"
      }
    ],
    "gitLink": "https://github.com/Hassane3/Disney-clone-project"
  },
  {
    "title": "syncfusion dashboard",
    "sub_title": "Bunch of UI syncfusion components",
    "thumbnail": "/images/projects/dashboard/dashboard-syncfusionpng.png",
    "technos": ["react.js", "redux", "syncfusion", "tailwinds"],
    "images": [
      {
        "images1": "image1",
        "images2": "image2"
      }
    ],
    "gitLink": "https://github.com/Hassane3/Dashboard-syncfusion"
  },
  // {
  //   "title": "louvre",
  //   "sub_title": "Clonage du site du Louvre",
  //   "thumbnail": "/images/projects/louvre/louvre_thumbnail.png",
  //   "technos": ["react.js", "javascript", "html", "css"],
  //   "images": [
  //     {
  //       "images1": "image1",
  //       "images2": "image2"
  //     }
  //   ],
  //   "gitLink": "https://github.com/Hassane3/Louvre"
  // },
  {
    "title": "glasgow kelvin college",
    "sub_title": "Une application pour gérer un stock universitaire",
    "thumbnail": "/images/projects/gkc/springburn-campus-front.jpg",
    "technos": ["php", "html", "css", "mysql", "photoshop"],
    "images": [
      {
        "images1": "image1",
        "images2": "image2"
      }
    ]
  },
  {
    "title": "shootring",
    "sub_title": "jeu 2d",
    "thumbnail": "/images/projects/shootring/shootring_ingame.png",
    "technos": [],
    "images": [
      {
        "images1": "image1",
        "images2": "image2"
      }
    ]
  }
]


export const Technos = [
  {
    "id": 0,
    "item": "react.js"
  },
  {
    "id": 1,
    "item": "html"
  },
  {
    "id": 2,
    "item": "css"
  },
  {
    "id": 3,
    "item": "php"
  },
  {
    "id": 4,
    "item": "java"
  },
  {
    "id": 5,
    "item": "wordpress"
  },
  {
    "id": 6,
    "item": "vue.js"
  },
  {
    "id": 7,
    "item": "react native"
  },
  {
    "id": 8,
    "item": "figma/xd"
  },
  {
    "id": 9,
    "item": "photoshop"
  },
  {
    "id": 10,
    "item": "illustrator"
  },
  {
    "id": 11,
    "item": "javascript"
  },
  {
    "id": 12,
    "item": "pwa"
  }

]

//The name must correspond to the title given in dataProject
//If the text must be followed with an image, so they must be surrounded in a div
//Class "row" = affichage côte à côte
export const Vitanimal = () => {

  return (
    // <div><div></div> <img></img></div> OU <div><img></img> <div></div></div>
    <div>
      <div>
        <div>

          <h4>Contexte :</h4>
          <p>
            Dans le cadre d’un projet universitaire, nous, membres d’un
            groupe de projet avons fait le choix d’un projet parmi d’autres,
            consistant à la création d’une application hybride pour le Zoo
            African Safari.
          </p>
          <br />

        </div>
        <img alt="logo vitanimal" src="/images/projects/vitanimal/ZAF-logo-md.png" />
      </div>


      <p>
        Le but de cette application est, dans un premier temps de mettre
        en place un outil permettant d’accompagner les vétérinaires dans
        leurs missions et ceci, par la transformation numérique de
        certaines fonctions en créant un système de gestion de données
        fluide et simple.<br />
      </p>
      <p>
        Dans un deuxième temps, nous créerons un deuxième volet qui,
        cette fois-ci sera destiné aux visiteurs du zoo et l’objectif
        sera de leur offrir un contenu interactif qui les accompagnera
        pendant leur visite du zoo.
      </p>
      <br />
      <p>
        En collaboration avec divers étudiants de différentes fillières,
        nous avons adopté la méthode agile qui est l'une des méthodes
        les plus adaptées pour un projet en étroite collaboration avec le commanditaire.
      </p>
      <br />
      <div className='wrap'>
        <img alt="" src="/images/projects/vitanimal/ZAF_0.png" />
        <img alt="" src="/images/projects/vitanimal/ZAF_1.png" />
        <img alt="" src="/images/projects/vitanimal/ZAF_2.png" />
        <img alt="" src="/images/projects/vitanimal/ZAF_3.png" />
        <img alt="" src="/images/projects/vitanimal/ZAF_4.png" />
        <img alt="" src="/images/projects/vitanimal/ZAF_5.png" />
      </div>
    </div>
  )
}
export const Shootring = () => {

  return (
    // <div><div></div> <img></img></div> OU <div><img></img> <div></div></div>
    <div>

      <div>

        <div>
          <h4>Contexte :</h4>
          <p>Le but du projet est de créer un mini jeu ayant comme caractéristiques :</p>
          <ul>
            <li>- Plusieurs niveaux, personnalisable ;</li>
            <li>- Un mécanisme de sauvegarde (score, progression) ;</li>
            <li>- Interactif et temps réel (JavaFX&Thread) ;</li>
            <li>- Utilisation d’une forge logicielle (Gitlab, Github,…)</li>
          </ul>

          <br />
        </div>
      </div>

      <div>
        <div>
          <h4>Choix du jeu :</h4>
          <p>Le travail étant en groupe, nous avons fait le choix d’un jeu de type Space Invader dont le principe est de contrôler un vaisseau dans un ring et d’esquiver ou de détruire les astéroïdes pour pouvoir monter dans les niveaux.</p>
          <br />
        </div>

      </div>

      <div>
        <img src="/images/projects/shootring/shootring_ingame.png" alt="jeu shoot ring" />
        <img src="/images/projects/shootring/shootring_ship.png" alt="jeu shoot ring" />

      </div>

      <div>
        <div>

          <h4>Modélisation et programmation</h4>
          <p>Nous avons fait le choix de modéliser tout d’abord un schéma de cas d’utilisation pour identifier les principales actions et les principaux acteurs pour ensuite modéliser le diagramme des classes avec ses attributs et méthodes.</p>

          <p>Après validation du schéma, nous avons implémenté le code en java avec l'adoption de l'architecture MVC</p>
        </div>

      </div>
      <img src="/images/projects/shootring/diagramme_de_classes.png" alt="diagramme de classes" />
    </div>
  )
}
export const GKC = () => {

  return (
    // <div><div></div> <img></img></div> OU <div><img></img> <div></div></div>
    <div>
      <div>
        <div>Ce projet a été réalisé lors de mon stage de première année de BTS.
          Le but du projet été de réaliser une application web locale pour le compte
          de université "Glasgow Kelvin College" et dont la fonction du site est de permettre la gestion du stock de l'université.
        </div>
        <img src="/images/projects/gkc/springburn-campus-front.jpg" alt="" />

      </div>

      <div>
        <div>
          <p>Les tâches effectuées à travers ce projet : </p>
          <ul>
            <li>- Réalisation de l’inventaire des différents stocks ;</li>
            <li>- Conception et modélisation de la base de données ;</li>
            <li>- Mise en place de l’application web </li>
          </ul>
        </div>
        <img src="/images/projects/gkc/gkc_login.png" alt="page login" />





      </div>
      <div>
        <img src="/images/projects/gkc/gkc_home.png" alt="page d'acceuil" />
        <div>
          <p>Les fonctions attendus :</p>
          <ul>
            <li>Permettre différents affichages et attribuer des permissions pour différents utilisateurs (étudiants, professeurs et staffs) ;
            </li>
            <li>Pouvoir modifier, ajouter et  supprimer des produits</li>
          </ul>
        </div>

      </div>


    </div>
  )
}
export const Louvre = () => {

  return (
    // <div><div></div> <img></img></div> OU <div><img></img> <div></div></div>
    <div>
      <p>Le contenu arrive bientôt</p>
    </div>
  )
}
export const Disneyplus = () => {

  return (
    <div>
      <div>
        <div>
          Ce projet personnelle consistait en un clonnage partiel de la page d'accueil du site "Disney +" en reproduisant le style
          d'afficahge ainsi que la mise en place d'un système d'authentification google via Firebase.
          <br /><br /><a href="https://disneyplus-clone-70347.web.app/">Lien vers le projet</a>
        </div>
        <img alt="" src="/images/projects/disney/disney_thumbnail.png" />

      </div>
    </div>
  )
}
export const Dashboard = () => {

  return (
    // <div><div></div> <img></img></div> OU <div><img></img> <div></div></div>
    <div>
      <p>Réalisation d'un dashboard avec React js ainsi que l'utilisation de la librairie UI Syncfusion.</p>
      <br />
      <a href="https://dashboard-syncfusion-app.netlify.app">lien vers le projet</a>
    </div>
  )
}
// export const Agneaudoleron = () => {

//   return (
//     <div>
//       <a href="https://www.agneaudoleron.com">www.agneaudoleron</a>
//       <p>Le contenu arrive bientôt</p>
//     </div>
//   )
// }


// export const projects = {
//   "title": "Vitanimal",
//   "sub_title": "application mobile en PWA",
//   "thumbnail": "/images/projets/vitanimal/oise.jpg",
//   "gitLink": "",
//   "technos": [
//     "figma/xd",
//     "react.js",
//     "javascript",
//     "html",
//     "css",
//     "php",
//     "pwa"
//   ],

// }
