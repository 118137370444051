import styled from "styled-components";

import { colors } from "../features/presetStyles";
import { Git, Linkedin } from "../features/icons";

function Header(props) {
  const { lightMode, setLightMode } = props

  return (
    <Box>
      <Container
        color={lightMode ? "#789A95" : "#040404"
        }
        transform={lightMode ? "rotateZ(0)" : "rotateZ(-180deg)"
        }
      >
        <img alt="" src="./images/noise-effect.jpg" />
        <img alt="" src="./images/blur-effect2.png"
        />

        <Profil>
          <div>
            <h1>Développeur full-stack</h1>
            <span>
              <a href={"https://linkedin.com/in/hassane-lamine-3159bb180/"} target="_blank" rel="noreferrer">
                <Linkedin color={colors.beige} width="1.2em" height="1.2em" />
              </a>
              <a href={"https://github.com/Hassane3"} target="_blank" rel="noreferrer">
                <Git color={colors.beige} width="1.2em" height="1.2em" />
              </a>
            </span>
          </div>
        </Profil>
        <LightModeButton
          width={lightMode ? "28px" : "24px "}
          sunTranslate={lightMode ? "translate3d(0)" : "translate3d(8px, -5px, 0)"}
          moonTranslate={lightMode ? "translate3d(0)" : "translate3d(-6px, 6px, 0)"}
          moonColor={lightMode ? colors.beige : colors.corail}
          moonShadow={lightMode ? "none" : "0px 0px 10px 2px rgb(" + colors.corailRGBWithoutcomam + ")"}
        >

          {/* Sun */}
          <div
            onClick={() => setLightMode(lightMode => !lightMode)}
          ></div>
          {/* Moon */}
          <div></div>



        </LightModeButton>

      </Container>
    </Box>
  );
}
const Box = styled.div`
/* .darkMode:after {
    background: linear-gradient(240deg, #040404, #000000) !important;
  } */
`;
const Container = styled.div`
  margin: 30px 30px 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 60px;
  /* .darkMode:after {
    background: linear-gradient(240deg, #040404, #000000) !important;
  } */

  &:after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -10;
    height: 100%;
    width: 100%;
    /* background: linear-gradient(45deg, #789A95, #B5DAD5); */
    background: ${props => props.color && props.color};
    
    background-color: ${props => props.color && props.color};
    transition: all ease 1s;
  }

  /* &:before {
    content: "";
    top: -50%;
    right: -50%;
    position: absolute;
    z-index: -5;
    height: 800px;
    width: 800px;
    background: radial-gradient(rgba(255,255,255,0.2) 5%,transparent 60%);
    border-radius: 50%;
    transform: bottom center;
    transform: ${props => props.transform && props.transform};
    transition: all ease 1s;
  } */
  
  > img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  > img:first-child {
    z-index: -5;
    opacity: 0.23;
  }
  > img:nth-child(2) {
    z-index: -1;
    opacity: 0.43;
 
  }
`;

const Profil = styled.div`
  width: 30vw;
  display: flex;

  > div:first-child {
    display: flex;
    flex-direction: column;

    h1 {
      color: ${colors.beige};
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
  a {
    cursor: pointer;
  }
  span a {
    margin-right: 10px;
  }

`;

const LightModeButton = styled.div`
    position: relative;
    width: 30px;
    height:30px;

    /* ASTRES */
    > div {
      flex: none;
      border-radius: 50%;
      height: 20px;
      width:20px;
      transition: all ease 1s;
      position: absolute;
    }

    //SUN
    > div:first-child {
      cursor: pointer;
      bottom: 0;
      left:0;
      z-index: 1;
      background-color: ${colors.greenBlack};
      transform: ${props => props.sunTranslate && props.sunTranslate};
      overflow: hidden;
      &:after {
        content: "";
          height: 130%;
          width: 120%;
          background-color: azure;
          position: absolute;
          right:100%;
          transform-origin: bottom right;
          border-radius: 50%;
        }
        
        &:hover {
          :after{
            animation: 0.3s ease 0.2s shinyEffect;
            @keyframes shinyEffect {
             from{
               transform: rotate(0)
             }
             to {
               transform: rotate(180deg);
             }
            }
        }
      }
     }

    //MOON
    > div:nth-child(2) {
      right:0;
      top:0;
      background-color: ${props => props.moonColor && props.moonColor};
      box-shadow: ${props => props.moonShadow && props.moonShadow};
      transform: ${props => props.moonTranslate && props.moonTranslate};
    }
`;
export default Header;
