import React from 'react'
import styled from 'styled-components'

import { colors } from '../features/presetStyles';
import { Cross } from '../features/icons';
import { FilterBtnContainner, FilterBtnStyle } from './Project';

const FilterModal = (props) => {
    const { handleIsFilterOpen, btnItemsState, btnItems, updateBtnItemsState, lightMode } = props;

    return (
        <Modal>
            <div>
                <h4>Filtres</h4>
                <i onClick={() => handleIsFilterOpen(false)}><Cross color={colors.beige} width="1em" height="1em" /></i>
            </div>
            <FilterBtnContainner>
                {btnItems.map((techno, index) => (
                    <FilterBtnStyle key={index} textColor={lightMode ? colors.greenBlack : colors.sweetBeige}
                        style={{
                            pointerEvents: "auto",
                            cursor: 'pointer',
                            boxShadow: btnItemsState[index] === true && "0px 0px 2px 1px rgb(" + colors.lightGreenRgb + " / 100%)",

                        }}
                        onClick={() => updateBtnItemsState(index)}
                    >
                        <span>{techno.item}</span>
                    </FilterBtnStyle>

                ))}
            </FilterBtnContainner>
        </Modal>
    )


}

const Modal = styled.div`
        position: absolute;
        z-index: 10;
        width: 200px; 
        top: 30px;
        left: -20px;
        border-radius: 16px;
        padding: 8px;
        background: linear-gradient(45deg, rgba(${colors.greenBlackRGB},0.1), rgba(${colors.sweetGreenRGB},0.45));
        overflow: hidden;
        backdrop-filter: blur(4px);
        box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 25%);
        
        
        >div:first-child {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 18px;
            h4 {
                color: ${colors.beige};
                font-size :14px;
                font-weight: 400;
                text-decoration: underline;

            }
            i {
                display:flex;
                align-items: center;
                justify-content: center;
                padding-right: 4px;
                cursor: pointer;
                pointer-events: auto;
                /* padding: 5px 5px; */
            }
        }
`;

export default FilterModal