import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors, sizes } from "../features/presetStyles";
import { ArrowTip, Cross, Filter, Git } from "../features/icons";
// import { animated } from "@react-spring/web";

// import projectDatas from "../projectDatas.json";
import { GKC, ProjectDatas, Technos } from "./projects/ProjectDatas";
import FilterModal from "./FilterModal";
import { Vitanimal, Shootring, Disneyplus, Dashboard } from "./projects/ProjectDatas";

const Project = (props) => {
  const { lightMode } = props;
  const btnItems = Technos.map((techno) =>
    JSON.parse(JSON.stringify(techno))
  );

  //STATES
  const [projectTab, setProjectTab] = useState(
    ProjectDatas.map((project) => {
      return project;
    })
  );
  const [projectsToDisplay, setProjectToDisplay] = useState(
    ProjectDatas.map((project) => {
      return project;
    })
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedTechnos, setSelectedTechnos] = useState([]);
  //Selected project is the first one in the array :
  const [selectedProject, setSelectedProject] = useState(projectsToDisplay[0]);
  const [btnItemsState, setBtnItemsState] = useState(btnItems.map(() => false));




  //ANIMATION
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    if (shouldAnimate && projectsToDisplay) {
      setTimeout(() => {
        const newProjectToDisplay = projectsToDisplay.map((project) => {
          return project;
        });
        //Re order projectTab:
        newProjectToDisplay.push(newProjectToDisplay.shift());
        setProjectToDisplay(newProjectToDisplay);
        setShouldAnimate(false);
        setSelectedProject(newProjectToDisplay[0]);
      }, 420);
    }
  }, [projectsToDisplay, shouldAnimate]);


  useEffect(() => {
    if (selectedTechnos.length) {
      const newProjectTab = projectTab.filter((project) => {
        let technoCount = 0;
        selectedTechnos.forEach(
          (techno) => project.technos.includes(techno.item) && technoCount++
        );

        if (technoCount === selectedTechnos.length) {
          return project;
        }
      });
      setProjectToDisplay(newProjectTab);

    } else {
      setProjectToDisplay(projectTab);
    }
  }, [selectedTechnos, projectTab]);

  const handleFitlteredTechno = (itemIndex) => {
    updateBtnItemsState(itemIndex);
  };
  const updateBtnItemsState = (itemIndex) => {
    const updatedBtnItemsState = btnItemsState.map((item, index) => {
      if (itemIndex === index) {
        //If item === false, meaning that it will get true after the "if", so we push it in the selectedTechnos table
        if (item === false) {
          setSelectedTechnos((selectedTechnos) => [
            ...selectedTechnos,
            btnItems[index],
          ]);
        }
        //Else If item === true, meaning that it will get false after the "if", so we retrieve it from the selectedTechnos table
        else if (item === true) {
          let monT = selectedTechnos.filter((item) => item.id !== itemIndex);
          setSelectedTechnos(monT);
        }
        return (item = !item);
      } else return item;
    });
    setBtnItemsState(updatedBtnItemsState);
  };

  const carousel = useRef();

  return (
    <Container>
      <h2>Projets</h2>
      <FilterContainer>
        <i onClick={() => setIsFilterOpen(!isFilterOpen)}>
          <Filter color={colors.beige} width="1.2em" height="1.2em" />
        </i>

        <FilterBtnContainner>
          {selectedTechnos.map((techno, index) => (
            <FilterBtnStyle key={index} textColor={lightMode ? colors.greenBlack : colors.sweetBeige}>
              <span>{techno.item}</span>
              <i onClick={() => handleFitlteredTechno(techno.id)}>
                <Cross color={colors.beige} width="1em" height="1em" />
              </i>
            </FilterBtnStyle>
          ))}
        </FilterBtnContainner>

        {isFilterOpen && (
          <FilterModal
            handleIsFilterOpen={setIsFilterOpen}
            btnItems={btnItems}
            btnItemsState={btnItemsState}
            updateBtnItemsState={handleFitlteredTechno}
            lightMode={lightMode}
          />
        )}
      </FilterContainer>

      <ContentSlider>
        <Content className={shouldAnimate ? "slideActivated" : undefined}
          color={lightMode ? colors.greenBlack : colors.sweetBeige}
        >
          <div>
            {selectedProject.technos.map((techno, index) => (
              <span key={index}>{techno}</span>
            ))}
            {selectedProject.gitLink && (
              <a href={selectedProject.gitLink} target="_blank" rel="noreferrer">
                <Git color={lightMode ? colors.greenBlack : colors.sweetBeige} width="1.4em" height="1.4em" />
              </a>
              // <i>
              //   
              // </i>
            )}
          </div>

          <div>
            <h4>{selectedProject.sub_title}</h4>
            <h3>{selectedProject.title}</h3>

            <div>
              {selectedProject.title === "vitanimal" && <Vitanimal />}
              {selectedProject.title === "shootring" && <Shootring />}
              {/* {selectedProject.title === "louvre" && <Louvre />} */}
              {selectedProject.title === "disney+" && <Disneyplus />}
              {selectedProject.title === "glasgow kelvin college" && <GKC />}
              {selectedProject.title === "syncfusion dashboard" && <Dashboard />}
              {/* {selectedProject.title === "agneau d'Oleron" && <Agneaudoleron />} */}
              {/* {<Vitanimal />} */}
            </div>
          </div>
        </Content>

        <Slider>
          <div ref={carousel}>
            {projectsToDisplay.map((project, index) => (
              <div
                key={index}
                className={shouldAnimate ? "slideActivated" : undefined}
                style={{ opacity: index > 3 && 0 }}
              >
                <img alt={project.title} src={project.thumbnail} />
              </div>
            ))}
          </div>

          {selectedTechnos.length ? (
            projectsToDisplay.length > 3 && (
              <i onMouseDown={() => setShouldAnimate(true)}>
                <ArrowTip color="#fff" width="1em" height="1em" />
              </i>
            )
          ) : (
            <i onMouseDown={() => setShouldAnimate(true)}>
              <ArrowTip color="#fff" width="1em" height="1em" />
            </i>
          )}
        </Slider>
      </ContentSlider>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 120px;
  padding-left: 20px;
  height: 100%;

  h2,
  h3,
  h4,
  p,
  span {
    font-family: "Poppins";
  }

  h2 {
    color: ${colors.beige};
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-left: 30px;
  }
  i {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:before {
    content: "";
    height: 100%;
    width: 90%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, ${colors.sweetGreen}, #0000);
    mix-blend-mode: overlay;
    border-radius: 16px;
  }
`;

const FilterContainer = styled.div`

  margin: 10px 0 10px 30px;
  display: flex;
  align-items: flex-start;
  position: relative;
  //Filter icon
  > i {
    margin-right: 20px;
    svg {
      filter: drop-shadow(0px -3px 1px rgb(0 0 0 / 30%));
      transition: all 0.2s;
      &:hover {
        filter: drop-shadow(0px 0px 2px rgb(0 0 0 / 30%));
      }
    }
    &:active {
      svg {
        filter: none;
      }
    }
  }

`;

export const FilterBtnContainner = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-auto-flow: column;
  column-gap: 12px;
  row-gap: 14px;
`;

export const FilterBtnStyle = styled.span`
  pointer-events: none;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%);
  color: ${props => props.textColor && props.textColor};
  span {
    padding: 0 6px;
    white-space: nowrap;
    font-weight: 300;
    color: ${props => props.textColor && props.textColor};
    transition: color ease 1s;
  }
  i {
    padding-right: 4px;
    pointer-events: auto;
    padding: 5px 5px;
  }

  &:active {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 25%);
    transition: box-shadow 0.2s;
  }
`;

const ContentSlider = styled.div`
  /* border: solid 1px pink; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;

  > .slideActivated {
    animation: 0.4s cubic-bezier(0.29, -0.04, 0.27, 1.24) 0s contentEntry;
    transform-origin: bottom;

    @keyframes contentEntry {
      from {
        transform: scale(0.99);
        opacity: 0.7;

      }
      to {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
`;

const Content = styled.div`
  width: 76%;
  background-color: rgba(${colors.lightGreenRgb}, 0.15);
  border-radius: 16px;
  box-shadow: 0px 3px 21px 0 rgb(0 0 0 / 8%);
  padding: 8px;
  position: relative;
  overflow: hidden;
  
  span {
    font-weight: 300;
    color: ${colors.beige};
  }
  p,h2,h3, h4, div  {
    color: ${props => props.color && props.color}
  }
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 20px;
    padding-bottom: 20px; 
  }

  > div:nth-child(2) {
    scroll-behavior: smooth;
    overflow-y: scroll;
    height: 86%;
    padding: 0 30px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin-bottom: 20px;

    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${colors.greenBlack};
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      /* background: teal;  */
    }

    h3 {
      font-size: 44px;
      font-weight: 300;
      color: ${colors.greenBlack};
      margin-top: 20px;
      text-transform: capitalize;
    }

    h4 {
      font-size: 14px;
      font-weight: 300;
      color: ${colors.beige};
      margin-top: 20px;
    }

    img {
      margin-bottom:4px ;
      margin-top:4px ;
    }
    .wrap {
      flex-wrap: wrap;
    }
    > div > div {

      display: flex;
      flex-direction: column;
      /* justify-content: space-evenly; */
      margin-top: 100px;
      
      >div {
        display:flex;
        align-items: center;
        margin-bottom: 60px;
        grid-gap: 20px;

        img {
          width: auto;
          height: 100%;
          max-height: 300px;
          max-width: 50%;
          /* object-fit: contain; */
        }
      }
    }

  }

  /* scroll-behavior: smooth;
  overflow-y: scroll;
  height: 72vh; */

  /* ::-webkit-scrollbar {
  width: 10px;
} */
  /* width */
`;
const Slider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  i {
    position: relative;
    bottom: -16px;
    svg {
      mix-blend-mode: multiply;
      filter: drop-shadow(0px -3px 1px rgb(0 0 0 / 30%));
      transition: all 0.2s;
      &:hover {
        filter: drop-shadow(0px 0px 2px rgb(0 0 0 / 30%));
      }
    }
    &:active {
      svg {
        filter: unset;
      }
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-gap: 16px;
    height: 396px;
    overflow: hidden;
    position: relative;
    padding: 0 16px 0 16px;

    div {
      height: ${sizes.sliderBoxHeight};
      width: ${sizes.sliderBoxWidth};
      min-height: ${sizes.sliderBoxHeight};
      opacity: 1;
      position: relative;
      border-radius: 16px;
      overflow: hidden;
      box-shadow: 0px 4px 7px 0 rgb(0 0 0 / 20%);
      /* animation: 0.4s cubic-bezier(0.29, -0.04, 0.27, 1.24) 0s boxSlideEntry;
      @keyframes boxSlideEntry {
        from {
          opacity: 0.5;
        }
        to {
          opacity: 1;
        }
      } */

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        filter: blur(1px);
      }
      &:after{
        content: "";
        position: absolute;
        top:0;
        left:0;
        z-index:0;
        height: 100%;
        width: 100%;
        background-color: rgba(0 0 0 / 30%);
      }
    }
    div:first-child {
      width: ${sizes.sliderFrstBoxWidth};
      height: ${sizes.sliderFrstBoxHeight};
      min-height: ${sizes.sliderFrstBoxHeight};
      opacity: 1;
      
      &:after {
        background-color: rgba(0 0 0 / 0%);
      }
      img {
        filter: none;
      }
      /* transform : translateY(0); */
      /* transition: all 1s; */
    }

    /**** Slide Activated */
    .slideActivated {
      transform: translateY(-${sizes.sliderFrstBoxHeight})
        translateY(-${sizes.sliderGap});
      transition: all linear 0.3s;
    }
    .slideActivated:first-child {
      transform: translateX(120px);
      opacity: 0;
      transition: all ease 0.6s;
      
    }
    .slideActivated:nth-child(2) {
      //add sliderFirstHeight to sliderGap to get the appropriate height:
      transform: translateY(-${sizes.sliderFrstBoxHeight})
        translateY(-${sizes.sliderGap});
      height: ${sizes.sliderFrstBoxHeight};
      width: ${sizes.sliderFrstBoxWidth};
      min-height: ${sizes.sliderFrstBoxHeight};
      transition: all linear 0.3s;
      &:after {
        background-color: rgba(0 0 0 / 0%);
      }
    }
    /* button {
      position: absolute;
      bottom: 0;
    } */
  }
`;
export default Project;
